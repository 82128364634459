export const PartTimeCheckboxList = [
    {
        order: 0,
        name: "pt_earthy",
        label: "EARTHY FOLK"
    },
    {
        order: 1,
        name: "pt_tasty",
        label: "TASTY FOLK"
    },
    {
        order: 2,
        name: "pt_techy",
        label: "TECHY FOLK"
    },
    {
        order: 3,
        name: "pt_flexy",
        label: "FLEXY FOLK"
    },
    {
        order: 4,
        name: "pt_gifty",
        label: "GIFTY FOLK"
    },
    {
        order: 5,
        name: "ft_vibey",
        label: "VIBEY FOLK"
    }
];
