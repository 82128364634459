export const FullTimeCheckboxList = [
    {
        order: 0,
        name: "ft_crafty",
        label: "CRAFTY FOLK"
    },
    {
        order: 1,
        name: "ft_artsy",
        label: "ARTSY FOLK"
    },
    {
        order: 2,
        name: "ft_oppity",
        label: "OPPITY FOLK"
    },
    {
        order: 3,
        name: "ft_earthy",
        label: "EARTHY FOLK"
    },
    {
        order: 4,
        name: "ft_tasty",
        label: "TASTY FOLK"
    },
    {
        order: 5,
        name: "ft_techy",
        label: "TECHY FOLK"
    },
    {
        order: 6,
        name: "ft_flexy",
        label: "FLEXY FOLK"
    },
];
