import React from 'react';
import './App.css';

import KundaRouter from './containers/App/KundaRouter';

function App() {
  return (
    <div className="App">
        <KundaRouter />
    </div>
  );
}

export default App;
