import React from "react";
import aboutPageImage from './assets/kunda-aboutpage-image.png';
import { Container } from "@mui/material";
import NavBar from "../../components/NavBar/NavBar";

import './styles.scss';

export class AboutPage extends React.Component {
    render() {
        return (
            <div className="AboutPage">
                <NavBar page="AboutPage" />
                <Container>
                    <div className="normality">
                        <div className="aboutTextContainer">
                            <h1 className="AboutHeaderText">ABOUT</h1>
                            <p className="AboutText">In the valley, time flows differently, as the gentle hums of life pulsate throughout the earth, crackling with an energy that connects us all. Here, we've created a community that thrives on the values of togetherness and nurturing, where the wilder things are encouraged to grow alongside the more familiar and conventional.</p>
                            <p className="AboutText">These days, it's easy to forget one of the cardinal rules of life, which is to love, nourish, and encourage all things to grow, no matter how unusual or unexpected they may be. The strange and unconventional require just as much warmth and light as the familiar and beautiful, and in the valley, we've found a way to embrace and celebrate the diversity of life.</p>
                            <p className="AboutText">As the seasons shift, from the warmth of Summer to the coolness of Autumn, and from the stillness of Winter to the awakening of Spring, we feel the magic of the valley come alive. The energy rises from our roots to our crowns, building within us until it bursts forth from our fingertips like miniature thunderstorms, reminding us that we are alive.</p>
                            <h2 className="AboutSubHeaderText">COMMUNITY</h2>
                            <p className="AboutText"><i>Kunda Valley</i>  is like an unfinished novel; filled with protagonists and created for television by everyone that shows up to take part. The valley itself is deeply rooted in fiction, yet, as the pages turn, it becomes more and more real. So, write away, no one knows what’s going to happen. But that’s where <i>fun</i> starts.</p>
                            <p className="AboutText">We are powered by community;  from creative contributions to admirable help with logistics and administration - the door is always open to anyone who would like to contribute in any capacity.</p>
                            <p className="AboutText">Our home is situated in the Diepklowe Conservancy – 550 acres of pristine nature reserve. We are only able to exist in a place of such sacred and untouched nature because we are a community that is proud to put the planet first in everything that we do. Over and above the practices we put in place, a R100 conservation fee to enter the nature reserve is required by all ticket holders – this fee goes towards maintaining and restoring the natural biodiversity of our home.</p>
                        </div>
                        <div className="aboutImageContainer">
                            <img className="AboutPageImage" src={aboutPageImage} alt="" />
                        </div>
                    </div>
                </Container>
            </div >
        )
    };
};

