import React from "react";
import { Container } from "@mui/material";
import NavBar from "../../components/NavBar/NavBar";

import './styles.scss';

import { ArtistsList } from "./utils/2023Lineup"
import { FacilitatorsPerformersList } from "./utils/2023Lineup"

export class ProgramPage extends React.Component {
    render() {
        return (
            <div className="ProgramPage">
                <NavBar page="ProgramPage" />
                <Container>
                    <h1 className="ProgramHeaderText">KUNDA PROGRAM</h1>
                    <p className="ProgramText">The Kunda Valley program incorporates a wide variety of fancied delights from music and performance to workshops and classes, with many surprises hidden in between. Our intention is to entice away from comfort zones and in so doing, provide engagement and experience to the backdrop of something new.</p>
                    <p className="ProgramText">We are excited to share the program for Kunda Valley 2023 with unannounced gems in store throughout!</p>
                    <h2 className="ProgramSubHeaderText">Artists</h2>
                    <div className="performer-container">
                        {ArtistsList.map(({ headliner, name, isLive, description, link }, index) => {
                            return (
                                <>
                                    {headliner ? <div className="performer-item performer-main">
                                        <h2 className="performer-headliner-text">{name}</h2>
                                        <h4 className="performer-description-text">{description}</h4>
                                    </div> :

                                        <div className="performer-item">
                                            <h3 className="performer-name-text">{name}</h3>
                                            {isLive ? <h4 className="performer-live-text">[ LIVE ]</h4> : null}
                                            <h4 className="performer-description-text">{description}</h4>
                                        </div>}
                                </>
                            );
                        })}
                    </div>
                    <h2 className="ProgramSubHeaderText">Performers & Facilitators</h2>
                    <div className="performer-container">
                        {FacilitatorsPerformersList.map(({ headliner, name, isLive, description, link }, index) => {
                            return (
                                <>
                                    {headliner ? <div className="performer-item performer-main">
                                        <h2 className="performer-headliner-text">{name}</h2>
                                        <h4 className="performer-description-text">{description}</h4>
                                    </div> :

                                        <div className="performer-item">
                                            <h3 className="performer-name-text">{name}</h3>
                                            {isLive ? <h4 className="performer-live-text">[ LIVE ]</h4> : null}
                                            <h4 className="performer-description-text">{description}</h4>
                                        </div>}
                                </>
                            );
                        })}
                    </div>
                </Container >
            </div >
        )
    };
};

