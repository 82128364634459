import React, { useState, useRef } from "react";
import { Alert, Container } from "@mui/material";
import NavBar from "../../components/NavBar/NavBar";
import EmailValidator from "../../utils/EmailValidator";
import { useNavigate } from "react-router-dom";

import './styles.scss';

const SubsidisedTicketPage = () => {
    const navigate = useNavigate();

    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [cellnumber, setCellnumber] = useState("");
    const [heardAboutUs, setHeardAboutUs] = useState("");
    const [attendanceJustification, setAttendanceJustification] = useState("");
    const [applyButtonText, setApplyButtonText] = useState("Apply");

    // Form Submission Logic
    const formRef = useRef(null);
    const formUrl = "https://sheet.best/api/sheets/beb028b7-0438-45b5-adcb-9f80a77c2478";
    // IMPORTANT https://script.google.com/macros/s/AKfycbwndjlBYi_fteQWPOet-zY63SwQV5a_tVpDeADfFiICqY4inLXjr8BeiDbsMui17yln/exec

    const handleSubmit = (event) => {
        event.preventDefault();
        const valid_email = EmailValidator(email);
        setApplyButtonText("Submitting...");

        fetch("https://script.google.com/macros/s/AKfycbwndjlBYi_fteQWPOet-zY63SwQV5a_tVpDeADfFiICqY4inLXjr8BeiDbsMui17yln/exec", { method: 'POST', body: new FormData(formRef.current) })
            .then(res => {
                navigate("/tickets/subsidised/done")
            })
            .catch(err => console.log(err))
    }


    // // Full Field Handling
    const handleFirstName = (event) => {
        setFirstName(event.target.value)
    }

    const handleLastName = (event) => {
        setLastName(event.target.value)
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)

        if (email == "") {
            setAlert(false);
            setAlertContent("");
        } else if (!EmailValidator(email)) {
            setAlert(true);
            setAlertContent("Please ensure you have used a valid email address");
        } else {
            setAlert(false);
            setAlertContent("");
        }
    }

    const handleCell = (event) => {
        setCellnumber(event.target.value)
    }

    const handleHeardAboutUs = (event) => {
        setHeardAboutUs(event.target.value)
    }

    const handleAttendanceJustification = (event) => {
        setAttendanceJustification(event.target.value)
    }


    return (
        <div className="SubsidisedTicketPage">
            <NavBar page="SubsidisedTicketPage" />
            <Container>
                <h1 className="SubsidisedTicketHeaderText">SUBSIDISED TICKET APPLICATION</h1>
                <p className="SubsidisedTicketText">
                    Kunda Valley acknowledges that our ticket price could prevent a more inclusive gathering. Unfortunately the cost of putting the production together is high and as a niche event with limited tickets we price our event fairly to curate something special for all those who attend. However, in order to increase the accessibility of Kunda Valley we offer a limited amount of subsidised tickets to those in more difficult financial situations.</p>
                <p className="SubsidisedTicketText">
                    If you are unable to afford the full ticket price due to financial reasons please complete the below form and we will reach out to you directly if your application is successful.</p>

                <form className="SubsidisedTicketAppForm left-justified" autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                    {alert ? <Alert severity='error'>{alertContent}</Alert> : <></>}
                    <div className="KundaFormRow">
                        <p className="SubsidisedTicketText SubsidisedFormField">First Name </p>
                        <input
                            required
                            type="text"
                            id="SubsidisedApp_First_Name"
                            name="SubsidisedApp_First_Name"
                            onChange={handleFirstName} />
                    </div>
                    <div className="KundaFormRow">
                        <p className="SubsidisedTicketText SubsidisedFormField">Last Name</p>
                        <input
                            required
                            type="text"
                            id="SubsidisedApp_Last_Name"
                            name="SubsidisedApp_Last_Name"
                            onChange={handleLastName} />
                    </div>
                    <div className="KundaFormRow">
                        <p className="SubsidisedTicketText SubsidisedFormField">Email Address</p>
                        <input
                            required
                            type="text"
                            id="SubsidisedApp_Email"
                            name="SubsidisedApp_Email"
                            placeholder="hello@example.com"
                            onChange={handleEmail} />
                    </div>
                    <div className="KundaFormRow">
                        <p className="SubsidisedTicketText SubsidisedFormField">Cellphone Number</p>
                        <input
                            required
                            type="text"
                            id="SubsidisedApp_Cell"
                            name="SubsidisedApp_Cell"
                            onChange={handleCell} />
                    </div>
                    <div className="KundaFormRow">
                        <p className="SubsidisedTicketText SubsidisedFormField">Where did you hear about us?</p>
                        <input
                            required
                            type="text"
                            id="SubsidisedApp_Heard_About_Us"
                            name="SubsidisedApp_Heard_About_Us"
                            placeholder=""
                            onChange={handleHeardAboutUs} />
                    </div>
                    <div className="KundaFormRow">
                        <p className="SubsidisedTicketText SubsidisedFormField">Why do you want to attend Kunda Valley this year?</p>
                        <input
                            required
                            type="text"
                            id="SubsidisedApp_Why_Kunda"
                            name="SubsidisedApp_Why_Kunda"
                            placeholder=""
                            onChange={handleAttendanceJustification} />
                    </div>
                    <div className="KundaFormRow">
                        <p className="SubsidisedTicketText left-justified">Would you be open to contributing time (before, during or after the festival) to help make the festival happen?</p>

                        <input type="radio" name="SubsidisedApp_Volunteer_Option" value="Yes" id="vol_yes" />
                        <label className="subsidisedRadioLabel" htmlFor="vol_yes">Yes</label>
                        <br />
                        <input type="radio" name="SubsidisedApp_Volunteer_Option" value="No" id="vol_no" />
                        <label className="subsidisedRadioLabel" htmlFor="vol_no">No</label>

                    </div>
                    <div className="KundaFormRow">
                        <p className="SubsidisedTicketText left-justified">What is your monthly income?</p>
                        <input type="radio" name="SubsidisedApp_Income" value="<5000" id="inc_less_5000" />
                        <label className="subsidisedRadioLabel" htmlFor="inc_less_5000">Less than R5 000</label>
                        <br></br>
                        <input type="radio" name="SubsidisedApp_Income" value="5000-10000" id="inc_5000_10000" />
                        <label className="subsidisedRadioLabel" htmlFor="inc_5000_10000">R5 000 - R10 000</label>
                        <br></br>
                        <input type="radio" name="SubsidisedApp_Income" value="10000-15000" id="inc_10000_15000" />
                        <label className="subsidisedRadioLabel" htmlFor="inc_10000_15000">R10 000 - R15 000</label>
                        <br></br>
                        <input type="radio" name="SubsidisedApp_Income" value="15000-20000" id="inc_15000_20000" />
                        <label className="subsidisedRadioLabel" htmlFor="inc_15000_20000">R15 000 - R20 000</label>
                        <br></br>
                        <input type="radio" name="SubsidisedApp_Income" value=">20000" id="inc_more_20000" />
                        <label className="subsidisedRadioLabel" htmlFor="inc_more_20000">More than R20 000</label>
                    </div>
                    <div className="KundaFormRow">
                        <p className="SubsidisedTicketText left-justified">Is there anything else you would like to share to further motivate your application?</p>
                        <input
                            type="text"
                            id="SubsidisedApp_Motivation"
                            name="SubsidisedApp_Motivation"
                            placeholder=""
                            onChange={handleAttendanceJustification} />
                    </div>
                    <input type="submit" className="SubsidisedApplyButton" value={applyButtonText} />
                </form>

            </Container>
        </div >
    )
};

export default SubsidisedTicketPage;