import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Container, Grid } from "@mui/material";

import articleCoverImage from './assets/KVArticleHeader.jpg';

import './styles.scss';
import { Link } from "react-router-dom";

export class KundaMailPage extends React.Component {
    render() {
        return (
            <div className="KundaMailPage">
                <NavBar />
                <Container>
                    <h1 className="KundaMailHeaderText">KUNDA MAIL</h1>
                    {/* <p className="KundaMailNormalText">Doesn't look like there is anything here just yet! Please pop back soon to see what's cracking</p> */}
                    <div className="KundaRow">
                        <div className="KundaColLeft">
                            <Link to="/kunda-mail/mailer/kv-mailer-1">
                                <div className="articleContainer">
                                    <img className="articleCoverImage" src={articleCoverImage} alt="" />
                                    <p className="KundaMailNormalText centred">Kunda Valley: Recap & Return</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="KundaRow">
                        <div className="KundaColLeft"></div>
                    </div>
                </Container>
            </div >
        )
    };
};
