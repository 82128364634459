import React from "react";
import { Container } from "@mui/material";
import NavBar from "../../components/NavBar/NavBar";

import './styles.scss';

export class VolunteerDonePage extends React.Component {
    render() {
        return (
            <div className="VolunteerSignupPage">
                <NavBar page="VolunteerPage"/>
                <Container>
                    <h1 className="VolunteerHeaderText">APPLICATION RECEIVED</h1>
                    <p className="VolunteerText">Thanks for your commitment to making magic at Kunda Valley 2023!</p>
                    <p className="VolunteerText">Our team will be in touch with you soon :)</p>
                    <a className="link" href="/"><button className="VolunteerSignupButton">Done</button></a>
                </Container >
            </div >
        )
    };
};

