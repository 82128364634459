import React from "react";
import { AppBar, Box, Divider, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import './styles.scss';

// Logo Images
import logoGreen from './assets/LogoGreen.png';
import logoOrange from './assets/LogoOrange.png';
import logoPurple from './assets/LogoPurple.png';
import logoCream from './assets/LogoCream.png';

// Open Menu Images
import openMenuGreen from './assets/OpenMenuGreen.png';
import openMenuCream from './assets/OpenMenuCream.png';
import openMenuOrange from './assets/OpenMenuOrange.png';
import openMenuPurple from './assets/OpenMenuPurple.png';

// Close Menu Images
import closeMenuGreen from './assets/CloseMenuGreen.png';
import closeMenuCream from './assets/CloseMenuCream.png';
import closeMenuOrange from './assets/CloseMenuOrange.png';
import closeMenuPurple from './assets/CloseMenuPurple.png';

// Icons
import soundcloudIcon from './assets/IconSoundcloud.svg';
import instaIcon from './assets/IconInstagram.svg';
import mailIcon from './assets/IconMail.svg';
import downArrow from './assets/ArrowDown.png'

const styles = {
    flex: {
        flexGrow: 1,
    },
    MenuItem: {

    }
};

const images = {
    "HomePage": {
        "mainIcon": logoGreen,
        "menuOpenIcon": openMenuGreen,
        "menuCloseIcon": closeMenuGreen
    },
    "AboutPage": {
        "mainIcon": logoOrange,
        "menuOpenIcon": openMenuOrange,
        "menuCloseIcon": closeMenuOrange
    },
    "VolunteerPage": {
        "mainIcon": logoCream,
        "menuOpenIcon": openMenuCream,
        "menuCloseIcon": closeMenuCream
    },
    "ProgramPage": {
        "mainIcon": logoPurple,
        "menuOpenIcon": openMenuPurple,
        "menuCloseIcon": closeMenuPurple
    },
    "SubsidisedTicketPage": {
        "mainIcon": logoPurple,
        "menuOpenIcon": openMenuPurple,
        "menuCloseIcon": closeMenuPurple
    }
}

function getImageByPage(page, key) {
    return images[page][key];
}

const NavBar = (props) => {
    const page = props.page;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [ticketDropDown, setTicketDropDown] = React.useState(false);


    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleTicketDropdown = () => {
        setTicketDropDown(!ticketDropDown)
    }

    return (
        <>
            <Box sx={styles.flex}>
                <AppBar color="transparent" position="sticky" disablegutters="true" elevation={0}>
                    <Toolbar>
                        <IconButton sx={{ mr: 2 }}>
                            <Link to="/" relative="path">
                                <img className="NavBarImage" src={getImageByPage(page, "mainIcon")} alt="" />
                            </Link>
                        </IconButton>
                        <Typography variant='title' color='inherit' sx={styles.flex}></Typography>
                        <IconButton onClick={handleOpenMenu}>
                            <img className="OpenMenuImage" src={getImageByPage(page, "menuOpenIcon")} alt="" />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Box>
            <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}  >
                <Link to="/about"><MenuItem><button className="MenuButton" onClick={handleCloseMenu}>ABOUT</button></MenuItem></Link>
                <Link to="/program"><MenuItem><button className="MenuButton" onClick={handleCloseMenu}>PROGRAM</button></MenuItem></Link>
                {/* <a href="https://dosh.ly/aQ4x" target="_blank"><MenuItem><button className="MenuButton" onClick={handleCloseMenu}>TICKETS</button></MenuItem></a> */}
                <MenuItem onClick={handleTicketDropdown}><button className="MenuButton">TICKETS</button><img className="NavBarDownArrow" src={downArrow} alt="dropdownicon"></img></MenuItem>
                {ticketDropDown ? (
                    <>
                        <a href="https://dosh.ly/aQ4x" target="_blank" rel="noreferrer"><MenuItem><button className="MenuSubButton" onClick={handleCloseMenu}>BUY NOW</button></MenuItem></a>
                        <Link to="/tickets/subsidised"><MenuItem><button className="MenuSubButton" onClick={handleCloseMenu}>SUBSIDISED TICKETS</button></MenuItem></Link>
                    </>
                ) : null}
                <Link to="/contributors"><MenuItem><button className="MenuButton" onClick={handleCloseMenu}>GET INVOLVED</button></MenuItem></Link>
                <Link to="/kunda-mail"><MenuItem><button className="MenuButton" onClick={handleCloseMenu}>KUNDA MAIL</button></MenuItem></Link>
                {/* <Link to="/login"><MenuItem><button className="MenuButton" onClick={handleCloseMenu}>PASSPORT</button></MenuItem></Link> */}
                <Divider />
                <MenuItem onClick={handleCloseMenu}>
                    <div className="IconContainer">
                        <a href="https://soundcloud.com/sounds-from-the-valley" target="_blank" rel="noreferrer"><button className="ImageButton"><img className="ImageIcon" src={soundcloudIcon} alt="" /></button></a>
                        <a href="https://www.instagram.com/kundavalley/" target="_blank" rel="noreferrer"><button className="ImageButton"><img className="ImageIcon" src={instaIcon} alt="" /></button></a>
                        <a href="mailto:whatsup@kundavalley.com" target="_blank" rel="noreferrer"><button className="ImageButton"><img className="ImageIcon" src={mailIcon} alt="" /></button></a>
                    </div>
                </MenuItem>
            </Menu>
        </>
    );
};

NavBar.defaultProps = {
    page: "HomePage"
};


export default NavBar;