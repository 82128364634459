import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { HomePage } from "../HomePage/HomePage";
import { AboutPage } from "../AboutPage/AboutPage";
import { VolunteerInfoPage } from "../VolunteerInfoPage/VolunteerInfoPage";
import VolunteerSignupPage from "../VolunteerSignupPage/VolunteerSignupPage";
import { ProgramPage } from "../ProgramPage/ProgramPage";
import { KundaMailPage } from "../KundaMailPage/KundaMailPage";
import { VolunteerDonePage } from "../VolunteerSignupPage/VolunteerDonePage";
import { KundaMailArticlePage } from "../KundaMailArticlePage/KundaMailArticlePage";
import SubsidisedTicketPage from "../SubsidisedTicketPage/SubsidisedTicketPage";
import { SubsidisedApplicationDonePage } from "../SubsidisedTicketPage/SubsidisedApplicationDonePage";

function KundaRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/program" element={<ProgramPage />} />
                <Route path="/contributors" element={<VolunteerInfoPage />} />
                <Route path="/contributors/signup" element={<VolunteerSignupPage />} />
                <Route path="/contributors/done" element={<VolunteerDonePage />} />
                <Route path="/kunda-mail" element={<KundaMailPage />} />
                <Route path="/kunda-mail/mailer/kv-mailer-1" element={<KundaMailArticlePage />} />
                <Route path="/tickets/subsidised" element={<SubsidisedTicketPage />} />
                <Route path="/tickets/subsidised/done" element={<SubsidisedApplicationDonePage />} />


            </Routes>
        </BrowserRouter>
    );
};

export default KundaRouter;