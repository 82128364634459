import React, { useState, useRef } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Alert, AlertTitle, Container } from "@mui/material";
import { FullTimeCheckboxList } from "./utils/FullTimeCheckboxList";
import { PartTimeCheckboxList } from "./utils/PartTimeCheckboxList";
import EmailValidator from "../../utils/EmailValidator";
import { useNavigate } from "react-router-dom";


import './styles.scss';

const VolunteerSignupPage = () => {
    const navigate = useNavigate();

    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [cellnumber, setCellnumber] = useState("");

    // const [volOptions, setVolOptions] = useState(
    //     new NodeList
    // );

    // Full Field Handling
    const handleFirstName = (event) => {
        setFirstName(event.target.value)
    }

    const handleLastName = (event) => {
        setLastName(event.target.value)
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)

        if (email == "") {
            setAlert(false);
            setAlertContent("");
        } else if (!EmailValidator(email)) {
            setAlert(true);
            setAlertContent("Please ensure you have used a valid email address");
        } else {
            setAlert(false);
            setAlertContent("");
        }
    }

    const handleCell = (event) => {
        setCellnumber(event.target.value)
    }

    // Checkbox Setup
    const [ftCheckedState, setftCheckedState] = useState(
        new Array(FullTimeCheckboxList.length).fill(false)
    );

    const [ptCheckedState, setptCheckedState] = useState(
        new Array(PartTimeCheckboxList.length).fill(false)
    );

    const handleFTSelectOption = (position) => {
        const updatedCheckedState = ftCheckedState.map((item, index) =>
            index === position ? !item : item
        );
        setftCheckedState(updatedCheckedState);
    }

    const handlePTSelectOption = (position) => {
        const updatedCheckedState = ptCheckedState.map((item, index) =>
            index === position ? !item : item
        );
        setptCheckedState(updatedCheckedState);
    }

    // Form Submission Logic
    const formRef = useRef(null);
    const formUrl = "https://sheet.best/api/sheets/beb028b7-0438-45b5-adcb-9f80a77c2478";

    const handleSubmit = (event) => {
        event.preventDefault();
        const valid_email = EmailValidator(email);
        const data = {}
        data.first_name = firstName;
        data.last_name = lastName;
        data.email = email;
        data.cellnumber = cellnumber;

        data.ft_crafty = ftCheckedState[0];
        data.ft_artsy = ftCheckedState[1];
        data.ft_oppity = ftCheckedState[2];
        data.ft_earthy = ftCheckedState[3];
        data.ft_tasty = ftCheckedState[4];
        data.ft_techy = ftCheckedState[5];
        data.ft_flexy = ftCheckedState[6];

        data.pt_earthy = ptCheckedState[0];
        data.pt_tasty = ptCheckedState[1];
        data.pt_techy = ptCheckedState[2];
        data.pt_flexy = ptCheckedState[3];
        data.pt_gifty = ptCheckedState[4];
        data.ft_vibey = ptCheckedState[5];

        fetch("https://sheet.best/api/sheets/e19472b2-3aef-4964-9598-1ce13536bd2a", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((r) => r.json())
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
                alert()
            });

        navigate("/contributors/done");
    }


    return (
        <div className="VolunteerSignupPage">
            <NavBar page="VolunteerPage" />
            <Container>
                <h1 className="VolunteerHeaderText">CONTRIBUTOR APPLICATION FORM</h1>
                <p className="VolunteerText">IF YOU WOULD LIKE TO BE INVOLVED IN BRINGING LIFE TO KUNDA VALLEY,<br />PLEASE SIGN-UP WITH THE FORM BELOW</p>
                <form className="VolunteerSignupForm" autoComplete="off" onSubmit={handleSubmit} ref={formRef} >
                    {alert ? <Alert severity='error'>{alertContent}</Alert> : <></>}
                    <div className="KundaRow">
                        <div className="KundaColLeft">
                            <input
                                required
                                className="volInput"
                                type="text"
                                id="Contributor_First_Name"
                                placeholder="FIRST NAME"
                                onChange={handleFirstName} />
                        </div>
                        <div className="KundaColRight">
                            <input
                                required
                                className="volInput"
                                type="text"
                                id="Contributor_Last_Name"
                                placeholder="LAST NAME"
                                onChange={handleLastName} />
                        </div>
                    </div>
                    <div className="KundaRow">
                        <input
                            required
                            className="volInput"
                            type="text"
                            placeholder="EMAIL"
                            id="Contributor_Email"
                            onChange={handleEmail} />
                    </div>
                    <div className="KundaRow">
                        <input
                            required
                            className="volInput"
                            type="text"
                            placeholder="CELL"
                            id="Contributor_Cellnumber"
                            onChange={handleCell} />
                    </div>
                    <p className="VolunteerText">CONTRIBUTOR ROLES</p>
                    <p className="VolunteerText">Please select your 3 (or more) favourite options so we can allocate you to the places where you are most needed!</p>
                    <div className="KundaRow">
                        <div className="KundaCol">
                            <p className="VolunteerText">FAMILY-TIME</p>
                            <div className="VolunteerOptionsContainer">
                                <p className="VolunteerText">Family-Time contributor slots for Kunda Valley 2023 have been closed.</p>
                                {/* {FullTimeCheckboxList.map(({ name, label, order }, index) => {
                                    return (
                                        <>
                                            <input
                                                type="checkbox"
                                                key={`fulltime-${order}`}
                                                id={name}
                                                name={name}
                                                value={name}
                                                checked={ftCheckedState[index]}
                                                onChange={() => handleFTSelectOption(index)}
                                            />
                                            <label className="VolunteerCheckboxLabel" htmlFor={name}>{label}</label>
                                            <br />
                                        </>
                                    );
                                })} */}
                            </div>
                        </div>
                        <div className="KundaCol">
                            <p className="VolunteerText">PARTY-TIME</p>
                            <div className="VolunteerOptionsContainer">
                                {PartTimeCheckboxList.map(({ name, label, order }, index) => {
                                    return (
                                        <>
                                            <input
                                                type="checkbox"
                                                key={`parttime-${order}`}
                                                id={name}
                                                name={name}
                                                value={name}
                                                checked={ptCheckedState[index]}
                                                onChange={() => handlePTSelectOption(index)}
                                            />

                                            <label className="VolunteerCheckboxLabel" htmlFor={name}>{label}</label>
                                            <br />
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <input type="submit" className="VolunteerSignupButton" value="Apply" />
                </form>

            </Container>
        </div>
    )
};

export default VolunteerSignupPage;
