import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Container, Divider } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";

import './styles.scss';

const theme = createTheme({
    typography: {
        "fontFamily": `"Jost"`
    },
    palette: {
        primary: {
            main: '#f4debd',
            dark: '#f4debd',
            light: '#f4debd'
        },
    },
});

export class VolunteerInfoPage extends React.Component {

    render() {
        return (
            <div className="VolunteerInfoPage">
                <NavBar page="VolunteerPage" />
                <ThemeProvider theme={theme}>
                    <Container>
                        <h1 className="VolunteerHeaderText">GET INVOLVED</h1>
                        <h2 className="VolunteerSubHeaderText">Family-time vs Party-time Folk</h2>
                        <p className="VolunteerText">Contributors are the ones who come together and help co-create the Kunda Valley experience. The multi-layered texture that community contribution brings is what makes Kunda Valley special.</p>
                        <p className="VolunteerText">This year we have broken up the contributions into two main categories: ‘Family-time folk’ and ‘Party-time folk’.</p>
                        <p className="VolunteerText">Family-time folk (long-term) are the ones who contribute all year round by attending our regular site visits and workshops - helping bring ideas to life and preparing for the main event. A minimum of 7 days pre-festival is required to qualify. Meals will be provided during all site visits.</p>
                        <p className="VolunteerText">Party-time folk (short-term) contribute in various ways during the main event - helping make sure things run smoothly and adding special touches to everyone's journey along the way. We require 3 x 6 hour shifts during the festival to qualify. Considering it is a 4 day festival this leaves plenty of time to enjoy the environment you help create. Meals will be provided on the days that you are working. </p>
                        <p className="VolunteerText">Regardless of which category you’d like to join we will require a R500 deposit to ensure your commitment as a contributor, this will then be refunded to you after the festival. </p>
                        <p className="VolunteerText">Have a look at the various contributor roles below. Please note some roles can be carried out as either family-time or party-time, others roles are either one or the other. </p>
                        <Divider className="VolunteerDivider" />
                        <h2 className="VolunteerSubHeaderText">Contributor Roles</h2>
                        <h3 className="VolunteerSubSubHeaderText">CRAFTY FOLK</h3>
                        <p className="VolunteerInfo"><i>FAMILY-TIME FOLK</i></p>
                        <p className="VolunteerText">Enjoy working with your hands to make cool things happen? Do you feel most at home with a drill in hand, pockets filled with screws and a cut on your left thumb? Crafty Folk are responsible for creating everything from infrastructure to large scale artworks. Previous knowledge in woodwork, fabrication or anything along these lines would be a bonus.</p>
                        <h3 className="VolunteerSubSubHeaderText">ARTSY FOLK</h3>
                        <p className="VolunteerInfo"><i>FAMILY-TIME FOLK</i></p>
                        <p className="VolunteerText">Do you enjoy lengthy chats whilst doing some form of creative task over and over and over and over again? Artsy folk are responsible for making things beautiful. From painting, to crocheting, to beading. Pretty much anything that at the end of the day gives people joy to look at.</p>
                        <h3 className="VolunteerSubSubHeaderText">OPPITY FOLK</h3>
                        <p className="VolunteerInfo"><i>FAMILY-TIME FOLK</i></p>
                        <p className="VolunteerText">Are you OPPIT? Does the idea of a functional spreadsheet make you happy? Do you love telling people what to do? Some may think a festival is all about creative flair, but in truth, the most part is organisation. If you’d like to help keep things in check then this is for you. Contribution from afar is a possibility here.</p>
                        <h3 className="VolunteerSubSubHeaderText">EARTHY FOLK</h3>
                        <p className="VolunteerInfo"><i>FAMILY-TIME FOLK & PARTY-TIME FOLK</i></p>
                        <p className="VolunteerText">Are you inspired by the idea of a sustainable future? Does the current way humans go about life make you cringe? The long-term goal is for Earthy folk to make Kunda Valley 100% sustainable. In the short-term Party-time Earthy Folk will help spread awareness around sustainability and help gently enforce our ethos during the main event.</p>
                        <h3 className="VolunteerSubSubHeaderText">TASTY FOLK</h3>
                        <p className="VolunteerInfo"><i>FAMILY-TIME FOLK & PARTY-TIME FOLK</i></p>
                        <p className="VolunteerText">Health is wealth. Tasty folk are responsible for keeping all the folks happy & healthy throughout all our many build weeks. We need head chefs and sous chefs but don’t worry about your level of expertise. As long as chopping lots of vegetables and making delicious plant-based meals makes you happy this is most likely the place for you.</p>
                        <h3 className="VolunteerSubSubHeaderText">TECHY FOLK</h3>
                        <p className="VolunteerInfo"><i>FAMILY-TIME FOLK & PARTY-TIME FOLK</i></p>
                        <p className="VolunteerText">Do you know your way around a circuit board? Does 2 core or 3 core make sense to you? Despite what the name might make you think, being a full-time Techy folk is actually probably the most creative way to contribute. We will be making a budget available for our techy folk to design, make and run their own micro projects - AV installations, mechatronics, kinetic sculptures… The catch? You need to kinda know what you’re doing or atleast have a very keen interest and ability to learn quickly.</p>
                        <p className="VolunteerText">Party-time techy folk are responsible for making sure the show goes on. You will be tasked with anything from stage management to being the right-hand to our lightning and sound engineers and everything in between.</p>
                        <h3 className="VolunteerSubSubHeaderText">FLEXY FOLK</h3>
                        <p className="VolunteerInfo"><i>FAMILY-TIME FOLK & PARTY-TIME FOLK</i></p>
                        <p className="VolunteerText">Jack of all trades, and find enjoyment in them all? Willing & able to help anywhere where needed? We could always use an extra hand somewhere.</p>
                        <h3 className="VolunteerSubSubHeaderText">GIFTY FOLK</h3>
                        <p className="VolunteerInfo"><i>PARTY-TIME FOLK</i></p>
                        <p className="VolunteerText">Would you like to gift something unique to the festival? We’re open to hear ideas on independent projects and performances. This could be a communal chill space, art installation, theme camp, etc or some form of performance or act of service.</p>
                        <h3 className="VolunteerSubSubHeaderText">VIBEY FOLK</h3>
                        <p className="VolunteerInfo"><i>PARTY-TIME FOLK</i></p>
                        <p className="VolunteerText">Are you good with keeping the energy light and vibrations high? Vibey folk will be carefully selected and undergo a brief training before the main event. Your task will be to create and maintain a safe space for anyone who’s not having a good time to come seek refuge - trip-sitting and tea-making where necessary.</p>
                        <Link to="/contributors/signup"><button className="VolunteerButton">Apply Now</button></Link>
                    </Container>
                </ThemeProvider>
            </div>
        )
    };
};

