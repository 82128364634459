import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Container } from "@mui/material";

import './styles.scss';

import articleImage1 from "./assets/KVArticle1-image1.jpg";
import articleImage2 from "./assets/KVArticle1-image2.jpg";
import articleImage3 from "./assets/KVArticle1-image3.jpg";
import articleImage4 from "./assets/KVArticle1-image4.jpg";
import articleImage5 from "./assets/KVArticle1-image5.jpg";
import articleImage6 from "./assets/KVArticle1-image6.jpg";

export class KundaMailArticlePage extends React.Component {
    render() {
        return (
            <div className="KundaMailArticlePage">
                <NavBar />
                <Container>
                    <h1 className="KundaMailHeaderText">Kunda Valley: Recap & The Return</h1>
                    <h2 className="KundaMailSubHeaderText ">A brief look at what was, in order to better understand what could be.</h2>
                    <p className="KundaMailNormalText KundaMailParagraph">Dearest Kunda folk,<br />
                        <br />
                        Hello :)
                        <br /><br />
                        It has been one whole year since we wrote our first chapter together, and what an introduction it was.
                        <br /><br />
                        This mail will introduce the next chapter of Kunda Valley. But before that, we thought we would take a look back at what was, in order to better understand what in the spirited pajamas could be next.
                        <br /><br />
                        But even before this, let’s go right back for all those who may be new here. :)
                    </p>
                    <h2 className="KundaMailSubHeaderText KundaMailParagraph">What is Kunda Valley?</h2>
                    <img className="KundaMailParagraphImage" src={articleImage1} />
                    <p className="KundaMailNormalText KundaMailParagraph">
                        More than just another festival, Kunda Valley is like an unfinished novel; filled with protagonists and created for television by everyone that shows up to take part. The valley itself is deeply rooted in fiction, yet, as the pages turn, it becomes more and more real. So, write away, no one knows what’s going to happen. But that’s where fun starts.
                        <br /><br />
                        Primarily we are a community orientated festival - from creative contributions to helping with logistics and management - the door is always open to anyone who would like to participate in any capacity – more on this later though.
                        <br /><br />
                        Our home is situated in the Diepklowe Conservancy – 550 acres of pristine nature reserve. We are only able to exist in a place of such sacred and untouched nature because we are a community that is proud to put the planet first in everything that we do. Over and above the practices we put in place, a R100 conservation fee to enter the nature reserve is required by all ticket holders – this fee goes towards maintaining and restoring the natural biodiversity of the area.
                    </p>
                    <h2 className="KundaMailSubHeaderText KundaMailParagraph">Kunda Valley Chapter 1: A Brief Look At What Was</h2>
                    <img className="KundaMailParagraphImage" src={articleImage2} />
                    <p className="KundaMailNormalText KundaMailParagraph">
                        Oh Chapter 1…
                        <br /><br />
                        A weekend characterized by a record breaking number of dead car batteries and the coldest nights of our lives. One could say a frosty reminiscence of what Woodstock camping might have looked like in a beautiful amalgamation of somewhat-organised chaos and the will to have a great time.
                        <br /><br />
                        However, in between it all, it was also a weekend filled with an abundance of divine festival experiences.
                        <br /><br />
                        We had 4 main spaces: The Atrium, Sanctuary, Westward Woods and the Yurt - all of which had moments where they shone so bright that it well surpassed any of our wildest expectations.
                        <br /><br />
                        We had over 50 contributors who all brought their absolute A-game. This resulted in top vibes during the build up and exceptional art & entertainment all weekend long.
                        <br /><br />
                        And lastly, we had over 400 first-time Kunda folk move in for the weekend - giving meaning to all the hard work that was done up until this point and this alone was simply beautiful to see!
                        <br /><br />
                        Moments of raw connection were bountiful.
                        <br /><br />
                        However, it was our first time putting this together and there were things we didn’t get 100% right. So let’s break down some things we thought worked well, some things we might change, concerns you have voiced and our proposed answer to them. If at any point you read something you feel like commenting on or adding to – please write it down and send it to us.
                    </p>
                    <p className="KundaMailNormalText KundaMailParagraph">
                        <ol type="1">
                            <li>One of our biggest achievements was definitely throwing the coldest party that anyone has ever attended. Shout out to everyone for surviving. Now that we’ve done the coldest party thing, it’s time for a new challenge and we have decided to shift our date to the Spring.</li>
                            <li>“There was too much going on and I was having to choose between 2 things I wanted to go to” – not everyone, but more than a few voiced this. The next edition will be longer allowing for more spaced out programming which we think will partially solve this. But at the same time, is too much of a good thing not a good thing?</li>
                            <li>It took us a while to get the pressure at the showers sorted on day 1 – we apologise for this. This is a problem we will hopefully be fixing with more permanent solutions - more on this later!</li>
                            <li>Parking & Camping and Camping & Parking were the epitome of oneness. We know it was super nice to camp by your car, but the truth is we didn’t put enough signage up for people to know where to camp and where to park and this could have led to a potentially dangerous situation. Thankfully there were no emergencies and everyone stayed until the end, but we wanted you all to know that we will be ensuring clearer parking, camping, and car camping areas in our next chapter.</li>
                        </ol>
                        If there was anything else you can think of that we could address it’s never too late to speak up! Our email is <a className="articleLink" href="mailto:whatsup@kundavalley.com" target="_blank">whatsup@kundavalley.com</a> :)
                    </p>
                    <h2 className="KundaMailSubHeaderText KundaMailParagraph">Chapter 2: A Look At What Could Be</h2>
                    <img className="KundaMailParagraphImage" src={articleImage3} />
                    <p className="KundaMailNormalText KundaMailParagraph">
                        Kunda Valley will return for 4 days & 4 nights over Heritage Day long weekend, 22-26 September 2023.
                        <br /><br />
                        We are particularly excited because a long weekend provides a little extra time to disconnect from where we came from and make space for all the new experiences to be had.
                        <br /><br />
                        What you might not know is that Chapter 1 was a bit of test run for us, because it was a chance for our partners in the Diepklowe Conservancy - Porcupine Hills and Southern Yurts - to see if they actually liked us. So it is with even greater excitement that we report back that WE passed the test!
                        <br /><br />
                        A big thank you to everyone who attended – we credit this success to a communal effort. We managed to gather a group of people who maintained consistent good vibes all weekend long whilst conducting themselves in a civilized manner and most importantly respecting the people and the nature around them. MOOP was kept to a minimum and after only 3 days of breakdown we were able to return the site as we found it. This is an achievement we should all be extremely proud of.
                        <br /><br />
                        The result of such an achievement is that we have been able to engage in more long term discussions with our venue partners – opening up the door to building more permanent infrastructure and aligning common goals over the next few years. This is a unique opportunity in the South African festival landscape and one that offers truly magical potential.
                        <br /><br />
                        Given this new long term approach, we have set out to build the following projects:
                    </p>
                    <p className="KundaMailNormalText KundaMailParagraph">
                        <ol type="1">
                            <li className="KundaMailSubHeaderText">Home</li>
                            <p>
                                The most important aspect of any gathering is the people. Forget the music, the stages, the lights and the décor. If the people aren’t happy, the festival is always going to have the looming sense that something is missing.
                                <br /><br />
                                For this reason we are prioritizing building a home for everyone to feel comfortable in and around. This will take the shape of some sort of communal area in the campsite (for congregating, braaing, etc) as well as toilets/showers.
                            </p>
                            <li className="KundaMailSubHeaderText">Semi-permanent Toilets/Showers</li>
                            <p>
                                For this next edition we would like to build a block of dry-composting toilets & semi-permanent showers. This will help us reduce the footprint of the festival by reducing the need to send unnecessary trailers around the country but we will also be able to cut down on the use of nasty chemicals to deal with human waste opening up the opportunity to create enriching soil which can then be given back to the land.
                            </p>
                            <li className="KundaMailSubHeaderText">Pathway Improvements</li>
                            <p>
                                We will be using some new parts of the farm and this requires fixing some of the pathways to make them both full-proof and fool-proof - because we all know that we like to be a little silly bananas every now and then.
                            </p>
                            <li className="KundaMailSubHeaderText">Art structures</li>
                            <p>
                                Large scale, permanent artworks and interactive structures is a passion that we share with our partners Porcupine Hills & Southern Yurts. Since your last visit to the valley there has already been a brand new Look-out Tower built which we have no doubt you will enjoy climbing and interacting with upon your return. The questions that remain are: What is next? & Who’s going to help us build it?
                            </p>
                            <li className="KundaMailSubHeaderText">The (Mega) Yurt</li>
                            <p>
                                Sjbdsijcdijncvwdcvdscvbidscbvsdicvbd!
                                <br /><br />
                                Standing 14m wide and 7.5m high. It’s all happening. Sneak preview on the schematic designs below.
                            </p>
                        </ol>
                    </p>
                    <img className="KundaMailParagraphImage" src={articleImage4} />
                    <img className="KundaMailParagraphImage" src={articleImage5} />
                    <h2 className="KundaMailSubHeaderText KundaMailParagraph articleUnderline">CALLING ALL FABRICATORS, ARTISTS, COOKS, CREATORS, BUMPERS AND GRINDERS!</h2>
                    <p className="KundaMailNormalText KundaMailParagraph">
                        Kunda Valley is a community orientated festival and given the goals set out above we welcome all the help we can get!
                        <br /><br />
                        We will be doing monthly site visits all year round as well as more frequent visits closer to the festival to give us the best chance of making things happen as well as grow as a community and get to know one another.
                        <br /><br />
                        If you would like to participate please read through the different contribution options and submit your application here: <a className="articleLink" href="https://www.kundavalley.com/contributors" target="_blank">https://www.kundavalley.com/contributors</a>
                    </p>
                    <p className="KundaMailSubHeaderText KundaMailParagraph">
                        That's all from us!
                        <br /><br />
                        With Love<br />
                        Kunda Valley
                    </p>
                    <p className="KundaMailNormalText KundaMailParagraph">
                        P.S. Our Speedy Folk (Phase 1) are 50% sold out, don’t snooze on it!<br />
                        <a className="articleLink" href="https://dosh.ly/aQ4x " target="_blank">https://dosh.ly/aQ4x</a>
                    </p>
                    <img className="KundaMailParagraphImage" src={articleImage6} />
                </Container>
            </div>
        )
    };
};
