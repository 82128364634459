export const ArtistsList = [
    {
        headliner: true,
        name: "MILLIE MCKEE",
        description: "WARM AGENCY ● BRISTOL, UK",
        link: ""
    },
    {
        headliner: true,
        name: "LA CASA TROPICAL",
        description: "TORONTO / AMSTERDAM / JOHANNESBURG",
        link: ""
    },
    {
        name: "ATHI MAQ",
        link: ""
    },
    {
        name: "BARISTA",
        link: ""
    },
    {
        name: "BENJI & FRIENDS",
        isLive: true,
        link: ""
    },
    {
        name: "BROTHA LEE",
        link: ""
    },
    {
        name: "COASTERS",
        isLive: true,
        link: ""
    },
    {
        name: "CJ BERGH",
        isLive: true,
        link: ""
    },
    {
        name: "CXNZIA",
        link: ""
    },
    {
        name: "DAN GREGORY",
        isLive: true,
        link: ""
    },
    {
        name: "DAV.ID",
        link: ""
    },
    {
        name: "DEANO",
        link: ""
    },
    {
        name: "DE MODA",
        link: ""
    },
    {
        name: "DISCARD",
        link: ""
    },
    {
        name: "DJ PHANNY",
        link: ""
    },
    {
        name: "FREEMAN LOTS",
        isLive: true,
        link: ""
    },
    {
        name: "GUILIETTE PRICE",
        isLive: true,
        link: ""
    },
    {
        name: "HUNTER",
        link: ""
    },
    {
        name: "JEWS FOR TECHNO",
        link: ""
    },
    {
        name: "JOAN",
        link: ""
    },
    {
        name: "JOE JOSEPH",
        link: ""
    },
    {
        name: "KUJENGA",
        isLive: true,
        link: ""
    },
    {
        name: "MAC MOTEL",
        isLive: true,
        link: ""
    },
    {
        name: "MANNY",
        link: ""
    },
    {
        name: "MILES SIEVRIGHT",
        isLive: true,
        link: ""
    },
    {
        name: "MISS P",
        link: ""
    },
    {
        name: "NAGMAL",
        link: ""
    },
    {
        name: "NATIVESOUND",
        link: ""
    },
    {
        name: "PAZ SHINA",
        link: ""
    },
    {
        name: "PIERRE-ESTIENNE",
        link: ""
    },
    {
        name: "RA MAVA",
        link: ""
    },
    {
        name: "RE.DAH",
        link: ""
    },
    {
        name: "RICARDO PINTO",
        link: ""
    },
    {
        name: "SUNSHINE & THE HOLY BASIL",
        isLive: true,
        link: ""
    },
    {
        name: "TAF'S",
        link: ""
    },
    {
        name: "VAT69",
        link: ""
    },
    {
        name: "VICMARI",
        link: ""
    },
    {
        name: "VINYL DESTINATION",
        link: ""
    },
    {
        name: "WHITE NITE",
        link: ""
    },
];

export const FacilitatorsPerformersList = [
    {
        name: "ANDRE HOUGH, STEVAN BUXT & PIERRE-ESTIENNE",
        description: "ANALOG VS DIGITAL",
        link: ""
    },
    {
        name: "BOHDI BREATHWORK",
        description: "CONSCIOUS BREATHING",
        link: ""
    },
    {
        name: "BRIDGET WOODS",
        description: "WELCOMING CEREMONY",
        link: ""
    },
    {
        name: "CAPE TOWN SWING",
        description: "DANCE CLASS",
        link: ""
    },
    {
        name: "CHUMA & FRIENDS",
        description: "LATE NIGHT COMEDY",
        link: ""
    },
    {
        name: "DANIELA SIMOES",
        description: "SOUND JOURNEY",
        link: ""
    },
    {
        name: "EMMA LOUISE",
        description: "EMBODIED DANCE",
        link: ""
    },
    {
        name: "JORDAN JAGGA",
        description: "NATURE'S ALLIES",
        link: ""
    },
    {
        name: "JULIAN JACKSON",
        description: "STAINED GLASS WORKSHOP",
        link: ""
    },
    {
        name: "JULIE GRANT",
        description: "SOUND HEALING",
        link: ""
    },
    {
        name: "KAELEIGH ELLIOTT",
        description: "VINYASA FLOW & FLYISUS MOVEMENT",
        link: ""
    },
    {
        name: "KRAS",
        description: "MUSIC & PERFORMANCE ART",
        link: ""
    },
    {
        name: "LIGHTFULYOU",
        description: "EXPERIENCE YOUR SACRED SELF",
        link: ""
    },
    {
        name: "MEYER",
        description: "FOREST BATHING",
        link: ""
    },
    {
        name: "SHUABE JACOBS",
        description: "CAPOEIRA",
        link: ""
    },
    {
        name: "SKY COPE",
        description: "SOUND JOURNEY",
        link: ""
    },
    {
        name: "STUART DODS & FRIENDS",
        description: "SOUND & VISION",
        link: ""
    },
    {
        name: "STRANGER FACES",
        description: "PERFORMANCE PIECE",
        link: ""
    },
    {
        name: "SUSKIA",
        description: "WILD WOMAN TANTRA",
        link: ""
    },
    {
        name: "TEA TANK",
        description: "TALES BY TEA",
        link: ""
    },
    {
        name: "THAT ECLECTIC",
        description: "SHORT FILM CINEMA",
        link: ""
    },
    {
        name: "VICTORIA YOUNG-PUGH",
        description: "MORNING MOVEMENT",
        link: ""
    },
    {
        name: "WHAT GOES UP",
        description: "PERFORMANCE PIECE",
        link: ""
    },
    {
        name: "ZWIDE NDWANDWE",
        description: "HISTORY OF AFRO JAZZ",
        link: ""
    },
    
];